import React, { Component } from "react";
import { Modal, Button  } from "react-bootstrap";
//import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";
import "./Login.css";
//import { Link } from "react-router-dom";

// We made 'shake and bake' and I helped!
export default class LoggedUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whoami : 'zzz'
    };
  }
async componentDidMount() {
  try {
    await Auth.currentSession()
      .then( (data) => {
        // possibly clone the response object: 'data' to capture other attributes
        this.setState({ whoami : data.idToken.payload.email })
        //console.log('user: ', data.idToken.payload.email);
        //console.log("whoami: ", this.state.whoami );
      })
      .catch(err => console.log(err));
  } catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  }

 getBack = (e) => {
    //console.log('click ', e) // the Synthetic event
    this.props.history.push("/");
  } // navigate to root "home" & render 'notes' list

// https://reactjs.org/docs/events.html#event-pooling
// https://react-bootstrap.github.io/components/modal/
// https://react-bootstrap.github.io/components/buttons/
  render() {
    return (
            <div className="static-modal">
              <Modal.Dialog>
                <Modal.Header>
                  <Modal.Title>Current User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Logged-IN  {this.state.whoami}
                </Modal.Body>
                <Modal.Footer>

                  <Button
                    bsStyle="primary"
                    onClick={this.getBack}
                    >Exit</Button>
                </Modal.Footer>
              </Modal.Dialog>
            </div>
    );
  }
} // end class

/*    <div className='Login'>
      <p>Logged-in user: {this.state.whoami}</p>
      <p>Click <b>Ol Scratchie</b> logo to list notes.</p>
    </div>  */
