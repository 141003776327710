export default { // 12.75MB File Size Limit - 31-Aug-19
  MAX_ATTACHMENT_SIZE: 12750000,
  s3: {
    REGION: "us-east-1",
    BUCKET: "07e2-upload"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://c4b2s6fkcg.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_XZlcJlse1",
    APP_CLIENT_ID: "74ne9qoa1ggfvco8lno14rcv5f",
    IDENTITY_POOL_ID: "us-east-1:71bcf9b2-3f27-4e95-bceb-ff05778fce9e"
  }
};

// App clientID - 74ne9qoa1ggfvco8lno14rcv5f
/*
{
  "sub": "e72e60d5-1074-49df-b7be-1018ce1264e2",
  "event_id": "61289b03-e6ff-11e8-91b3-25b3384b7f96",
  "token_use": "access",
  "scope": "aws.cognito.signin.user.admin",
  "auth_time": 1542084515,
  "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_XZlcJlse1",
  "exp": 1542158796,
  "iat": 1542155197,
  "jti": "29711aed-4a50-4077-8c08-45aa18e16a63",
  "client_id": "74ne9qoa1ggfvco8lno14rcv5f",
  "username": "e72e60d5-1074-49df-b7be-1018ce1264e2"
}
*/
