import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import LoggedUser from "./containers/LoggedUser";
import UserPosition from "./containers/UserPosition";
import ResetPassword from "./containers/ResetPassword";
import ChangePassword from "./containers/ChangePassword";
import ChangeEmail from "./containers/ChangeEmail";
import Settings from "./containers/Settings";
import Signup from "./containers/Signup";
import NewNote from "./containers/NewNote";
import Notes from "./containers/Notes";

import AppliedRoute from "./components/AppliedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/"
      exact component={Home}
      props={childProps}
    />

    <AppliedRoute path="/notes/new"
      exact component={NewNote}
      props={childProps}
    />

    <AppliedRoute path="/notes/:id"
      exact component={Notes}
      props={childProps}
    />

    <AppliedRoute path="/signup"
      exact component={Signup}
      props={childProps}
    />

    <AppliedRoute path="/login"
      exact component={Login}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/settings"
      exact
      component={Settings}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/settings/password"
      exact
      component={ChangePassword}
      props={childProps}
    />

        <AuthenticatedRoute
          path="/settings/whoami"
          exact
          component={LoggedUser}
          props={childProps}
        />

        <AuthenticatedRoute
          path="/settings/whereami"
          exact
          component={UserPosition}
          props={childProps}
        />

    <AuthenticatedRoute
      path="/settings/email"
      exact
      component={ChangeEmail}
      props={childProps}
    />

    <UnauthenticatedRoute
      path="/login/reset"
      exact
      component={ResetPassword}
      props={childProps}
    />

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
