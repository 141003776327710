import React, { Component } from "react";
import { Modal, Button  } from "react-bootstrap";
//import LoaderButton from "../components/LoaderButton";
import { API } from "aws-amplify";
import "./Login.css";
//import { Link } from "react-router-dom";

// We made 'shake and bake' and I helped!
export default class UserPosition extends Component {
  constructor(props) {
    super(props);
    this.fix = {lat: 0, lng: 0, time: 0};
    this.state = {
      whereami : 'zzz',
      geoFix : ''
    };
  }
// code a poyfill - detailed mjp 2-Dec-18
// https://www.udemy.com/progressive-web-app-pwa-the-complete-guide/learn/v4/t/lecture/7867546?start=29
async componentDidMount() {
  if (!('geolocation' in navigator)) {
    // add polyfill for Safari & Mozilla
    alert("Geolocation - NOT SUpported");
    return;
  } // API available?  */
  try{
    await navigator.geolocation.getCurrentPosition( (position) => {
      this.fix = {
        lat: position.coords.latitude.toFixed(6),
        lng: position.coords.longitude.toFixed(6),
        time: position.timestamp
      } // capture lat/lon
      this.setState({ geoFix : this.fix })
      //console.log("Fix: ", this.fix)
    })
  } catch(e) {
    alert('Location Fail', e)
  }
}

 getBack = (e) => {
    //console.log('click ', e) // the Synthetic event
    this.props.history.push("/");
  } // navigate to root "home" & render 'notes' list

// https://reactjs.org/docs/events.html#event-pooling
// https://react-bootstrap.github.io/components/modal/
// https://react-bootstrap.github.io/components/buttons/
  render() {
    return (
            <div className="static-modal">
              <Modal.Dialog>
                <Modal.Header>
                  <Modal.Title>Current Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Position: {this.state.geoFix.lat}  {this.state.geoFix.lng}</p>
                  <p>Fix Time: {Date(this.state.geoFix.time)}</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    bsStyle="primary"
                    onClick={this.getBack}
                    >Exit</Button>
                </Modal.Footer>
              </Modal.Dialog>
            </div>
    );
  }
} // end class
