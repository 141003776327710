import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";

import "./Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);
//console.log('Home: ', props)
    this.state = {
      isLoading: true,
      notes: []
    };
  }

  async componentDidMount() {
  if (!this.props.isAuthenticated) {
    return;
  }

  try {
    const notes = await this.notes(); // making the DB GETs
    this.setState({ notes }); // array of objects - all notes from DynamoDB
  } catch (e) {
    alert(e);
  }

  this.setState({ isLoading: false });
}

notes() {  // API imported from AWS CLI - aws-amplify
  return API.get("notes", "/notes");  // Array of objects - all User records DymanoDB
  // https://aws-amplify.github.io/amplify-js/api/classes/storageclass.html#get
} // END notes()


// notes object array is re-ordered - mjp 24-Nov-2018
// return in a new array - evaluate Array.sort()
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
renderNotesList(notes) {
  //console.log('renderNotes ', typeof notes)
  // reorder 'notes' object 'arg'
  notes.sort(
    ( a, b ) => {
      let noteA = a.content.substr(0,20);
      let noteB = b.content.substr(0,20);
      if( noteA.toUpperCase() < noteB.toUpperCase() ) {
        return -1;
      } else {
        return 1;
      }
    }
  ) // END sort -- wow, this worked.

  return [{}].concat(notes).map(
    (note, i) =>
      i !== 0
        ? <LinkContainer
            key={note.noteId}
            to={`/notes/${note.noteId}`}
          >
            <ListGroupItem header={note.content.trim().split("\n")[0]}>
              {"Created: " + new Date(note.createdAt).toLocaleString()}
            </ListGroupItem>
          </LinkContainer>
        : <LinkContainer
            key="new"
            to="/notes/new"
          >
            <ListGroupItem>
              <h4>
                <b>{"\uFF0B"}</b> Create a new note
              </h4>
            </ListGroupItem>
          </LinkContainer>
  );
}

  renderLander() {
    return (
      <div className="lander">
        <h1>Ol' Scratchie II</h1>
        <p>A note and a file for your thoughts.</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }

  renderNotes() {
    return (
      <div className="notes">
        <PageHeader>Your Notes</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderNotesList(this.state.notes)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderNotes() : this.renderLander()}
      </div>
    );
  }
}

/*      console.log('notes is ', typeof notes)
        console.log('notes[0].content is ',typeof notes[0].content)
        console.log(notes[0].content);
        console.log(notes)
*/
